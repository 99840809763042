var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
        [
          _c("v-data-table", {
            attrs: {
              id: "phoneList",
              height: _vm.tableSize,
              headers: _vm.headers,
              "items-per-page": _vm.pageSize,
              items: _vm.phoneList,
              search: _vm.search,
              loading: _vm.loading,
              "item-key": "id",
              dense: "",
              "show-select": "",
              "single-select": "",
              "fixed-header": "",
              "fixed-footer": ""
            },
            on: {
              "update:search": function($event) {
                _vm.search = $event
              },
              "click:row": _vm.emitSelect,
              "item-selected": _vm.selectRow
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c(
                        "v-toolbar",
                        {
                          staticStyle: { cursor: "default" },
                          attrs: { flat: "" }
                        },
                        [
                          _c(
                            "v-toolbar-title",
                            { staticClass: "ml-2 title" },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-phone-log")
                              ]),
                              _vm._v(" Phone ")
                            ],
                            1
                          ),
                          _c("v-divider", {
                            staticClass: "mx-4",
                            attrs: { inset: "", vertical: "" }
                          }),
                          _vm.hasWriteAccess
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    id: "newPhoneBtn",
                                    color: "primary",
                                    dark: "",
                                    absolute: "",
                                    right: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.createNewPhone()
                                    }
                                  }
                                },
                                [_vm._v(" Add Phone ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item.created_on",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.created_on != null
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .moment(item.created_on)
                                    .format(_vm.$config.timestamp)
                                ) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.updated_on",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.updated_on != null
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .moment(item.updated_on)
                                    .format(_vm.$config.timestamp)
                                ) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          }),
          _vm.creating === true || _vm.editing === true
            ? _c(
                "v-dialog",
                {
                  on: {
                    "click:outside": function($event) {
                      return _vm.closeEdit()
                    }
                  },
                  model: {
                    value: _vm.showDialog,
                    callback: function($$v) {
                      _vm.showDialog = $$v
                    },
                    expression: "showDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-pencil")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("capitalize")(_vm.crud)) +
                              " Phone "
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "closeEdit",
                                icon: "",
                                absolute: "",
                                right: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.closeEdit()
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { "justify-end": "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "span",
                                  { staticClass: "req-diclaimer float-right" },
                                  [_vm._v("* = Required")]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6", sm: "6", md: "3" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Phone Type *",
                                      items: _vm.phone_types,
                                      "item-text": "name",
                                      "item-value": "id",
                                      color: "primary",
                                      dense: "",
                                      outlined: "",
                                      required: "",
                                      "return-object": "",
                                      rules: [
                                        _vm.required(
                                          "Phone Type",
                                          _vm.phone.phone_type.id
                                        )
                                      ]
                                    },
                                    model: {
                                      value: _vm.phone.phone_type,
                                      callback: function($$v) {
                                        _vm.$set(_vm.phone, "phone_type", $$v)
                                      },
                                      expression: "phone.phone_type"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", sm: "6", md: "3" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.regionCodes,
                                      autocomplete: "new-password",
                                      outlined: "",
                                      "item-text": "name",
                                      "return-object": "",
                                      label: "Region Code",
                                      dense: ""
                                    },
                                    model: {
                                      value: _vm.region,
                                      callback: function($$v) {
                                        _vm.region = $$v
                                      },
                                      expression: "region"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", sm: "6", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "phone",
                                      dense: "",
                                      label: "Phone Number *",
                                      outlined: "",
                                      rules: [
                                        _vm.required(
                                          "Phone Number",
                                          _vm.phone.phone_number
                                        )
                                      ]
                                    },
                                    on: { change: _vm.formatPhone },
                                    model: {
                                      value: _vm.phone.phone_number,
                                      callback: function($$v) {
                                        _vm.$set(_vm.phone, "phone_number", $$v)
                                      },
                                      expression: "phone.phone_number"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { align: "end" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "d-flex justify-end" },
                                [
                                  _vm.editing === true
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-4",
                                          attrs: {
                                            id: "editBtn",
                                            color: "success",
                                            disabled: _vm.disabled(_vm.phone, [
                                              "phone_type.id",
                                              "phone_number"
                                            ])
                                          },
                                          on: { click: _vm.putPhone }
                                        },
                                        [_vm._v(" Update ")]
                                      )
                                    : _vm._e(),
                                  _vm.creating === true
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-4",
                                          attrs: {
                                            id: "createBtn",
                                            color: "success",
                                            disabled: _vm.disabled(_vm.phone, [
                                              "phone_type.id",
                                              "phone_number"
                                            ])
                                          },
                                          on: { click: _vm.postPhone }
                                        },
                                        [_vm._v(" Create ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-4",
                                      attrs: {
                                        id: "resetBtn",
                                        color: "warning"
                                      },
                                      on: { click: _vm.reset }
                                    },
                                    [_vm._v(" Reset ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }