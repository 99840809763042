<template>
  <v-form ref="form" v-model="valid">
    <v-container class="ma-0 pa-0" fluid>
      <v-data-table
        id="phoneList"
        :height="tableSize"
        :headers="headers"
        :items-per-page="pageSize"
        :items="phoneList"
        :search.sync="search"
        :loading="loading"
        item-key="id"
        dense
        show-select
        single-select
        fixed-header
        fixed-footer
        v-model="selected"
        @click:row="emitSelect"
        @item-selected="selectRow">
        <template v-slot:top>
          <v-toolbar flat style="cursor: default;">
            <v-toolbar-title class="ml-2 title">
              <v-icon left>mdi-phone-log</v-icon> Phone
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn
              v-if="hasWriteAccess"
              id="newPhoneBtn"
              color="primary"
              dark
              absolute
              right
              @click="createNewPhone()">
              Add Phone
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.created_on`]="{ item }">
          <span v-if="item.created_on != null">
            {{ moment(item.created_on).format($config.timestamp) }}
          </span>
        </template>
        <template v-slot:[`item.updated_on`]="{ item }">
          <span v-if="item.updated_on != null">
            {{ moment(item.updated_on).format($config.timestamp) }}
          </span>
        </template>
      </v-data-table>

      <v-dialog
        v-model="showDialog"
        v-if="creating === true || editing === true"
        @click:outside="closeEdit()">
        <v-card flat>
          <v-card-title>
            <v-icon left>mdi-pencil</v-icon>
            {{ crud | capitalize }} Phone
            <v-btn id="closeEdit" icon absolute right @click="closeEdit()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row justify-end>
              <v-col>
                <span class="req-diclaimer float-right">* = Required</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="3">
                <v-select
                  label="Phone Type *"
                  :items="phone_types"
                  item-text="name"
                  item-value="id"
                  v-model="phone.phone_type"
                  color="primary"
                  dense
                  outlined
                  required
                  return-object
                  :rules="[required('Phone Type', phone.phone_type.id)]">
                </v-select>
              </v-col>
              <v-col cols="6" sm="6" md="3">
                <v-autocomplete
                  :items="regionCodes"
                  autocomplete="new-password"
                  outlined
                  v-model="region"
                  item-text="name"
                  return-object
                  label="Region Code"
                  dense>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field
                  type="phone"
                  dense
                  v-model="phone.phone_number"
                  @change="formatPhone"
                  label="Phone Number *"
                  outlined
                  :rules="[required('Phone Number', phone.phone_number)]">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="end">
              <v-col class="d-flex justify-end">
                <v-btn
                  id="editBtn"
                  v-if="editing === true"
                  color="success"
                  class="mr-4"
                  :disabled="disabled(phone, ['phone_type.id', 'phone_number'])"
                  @click="putPhone">
                  Update
                </v-btn>
                <v-btn
                  id="createBtn"
                  v-if="creating === true"
                  color="success"
                  class="mr-4"
                  :disabled="disabled(phone, ['phone_type.id', 'phone_number'])"
                  @click="postPhone">
                  Create
                </v-btn>
                <v-btn
                  id="resetBtn"
                  color="warning"
                  class="mr-4"
                  @click="reset">
                  Reset
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-form>
</template>
<script>
import PhoneNumber from 'awesome-phonenumber'
import countryList from 'country-list'
import Phone from '../../axios/party-phone-endpoint'

import { cloneDeep } from 'lodash'

// mixins
import { displayAlert } from '@/mixins/alert'
import { formValidation } from '@/mixins/formvalidation'
import { fullwidth } from '@/mixins/fullwidth'
import { userAccess } from '@/mixins/user-access'
export default {
  name: 'Phone',
  data () {
    return {
      search: '',
      pageSize: 10,
      pageSizes: [10, 20],
      page: 1,
      info: null,
      valid: true,
      empty: false,
      creating: false,
      editing: false,
      crud: 'Create',
      selected: [],
      select: null,
      bindProps: {
        mode: 'international',
        defaultCountry: 'US',
        required: true,
        preferredCountries: ['US', 'AU'],
        enabledFlags: true,
        enabledCountryCode: false,
        placeholder: 'Enter a phone number',
        disabledFormatting: false,
        disabledFetchingCountry: false
      },
      phoneInput: {
        number: {
          input: '',
          national: '',
          international: '',
          e164: ''
        },
        valid: false,
        country: undefined
      },
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 10) || 'Name must be less than 10 characters'
      ],
      phone: {},
      phoneData: {
        id: '',
        party_id: '',
        phone_type: { id: '', name: '' },
        phone_number: '',
        created_by: '',
        updated_by: '',
        created_on: '',
        updated_on: ''
      },
      phoneList: [],
      region: {
        code: 'US',
        name: 'United States of America'
      }
    }
  },
  props: ['value'],
  mixins: [displayAlert, formValidation, fullwidth, userAccess],
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  created () {
    this.nonTableHeight = 403
    this.getPhoneList()
    this.phone.party_id = this.value
  },
  computed: {
    phone_types () {
      return this.$store.getters.phone_types
    },
    regionCodes () {
      const cl = countryList.getCodeList()
      return Object.entries(cl).map(([key, val]) => {
        return { code: key, name: val }
      })
    },
    headers () {
      return [
        { text: 'Phone Type', sortable: true, filterable: true, value: 'phone_type.name', align: 'center' },
        { text: 'Phone Number', sortable: true, filterable: true, value: 'phone_number', align: 'center' },
        { text: 'Created By', sortable: true, filterable: true, value: 'created_by', align: 'center' },
        { text: 'Created On', sortable: true, filterable: true, value: 'created_on', align: 'center' },
        { text: 'Updated By', sortable: true, filterable: true, value: 'updated_by', align: 'center' },
        { text: 'Updated On', sortable: true, filterable: true, value: 'updated_on', align: 'center' }
      ]
    }
  },
  methods: {
    formatPhone (data) {
      this.phone.phone_number = new PhoneNumber(data, this.region.code).getNumber('international')
    },
    async postPhone () {
      this.valid = false
      this.validate()

      const phoneData = {
        party_id: this.value,
        phone_type_id: this.phone.phone_type.id,
        phone_number: cloneDeep(new PhoneNumber(this.phone.phone_number, this.region.code).getNumber('e164')),
        created_by: this.$store.getters.email
      }
      if (this.valid) {
        await Phone.post(phoneData).then(() => {
          this.emitAlert(true, 'success', 'Your party\'s phone number has been successfully created!')
          this.getPhoneList()
          this.reset()
        }).catch(err => {
          this.handleError(err)
        })
      }
    },
    async putPhone () {
      this.valid = false
      this.validate()
      const phoneClone = cloneDeep(this.phone)
      if (phoneClone.phone_type.id) {
        phoneClone.phone_type = phoneClone.phone_type.id
      }
      const phoneData = {
        party_id: this.value,
        phone_type_id: phoneClone.phone_type,
        phone_number: cloneDeep(new PhoneNumber(phoneClone.phone_number, this.region.code).getNumber('e164')),
        updated_by: this.$store.getters.email
      }
      if (this.valid) {
        await Phone.put(this.phone.id, phoneData).then(() => {
          this.emitAlert(true, 'success', "Your party's phone number has been successfully updated!")
          this.getPhoneList()
          this.reset()
        }).catch(err => {
          this.handleError(err)
        })
      }
    },
    async getPhoneList () {
      this.loading = true
      await Phone.getList(this.value, 100, 0)
        .then(response => {
          this.phoneList = response.data.map(entry => {
            entry.phone_number = new PhoneNumber(entry.phone_number).getNumber('international')
            return entry
          })
        }).catch(err => {
          this.handleError(err)
        }).finally(() => {
          this.loading = false
        })
    },
    async getPhoneEdit (key) {
      await Phone.get(key.id)
        .then(response => {
          this.phone = response.data
          const pn = new PhoneNumber(response.data.phone_number)
          const regionCode = pn.getRegionCode()
          const regionName = countryList.getName(regionCode)
          this.region = { code: regionCode, name: regionName }
          this.phone.phone_number = pn.getNumber('international')

          this.empty = false
          this.editing = true
          this.creating = false
          this.crud = 'Update'
        })
        .catch((err) => {
          this.handleError(err)
        })
    },
    selectRow (row) {
      this.crud = 'Update'
      if (row.value) {
        this.phone = cloneDeep(row.item)
        this.editing = true
        this.creating = false
        this.showDialog = true
        this.resetAlert()
      } else {
        this.closeEdit()
      }
    },
    emitSelect (key, row) {
      if (row.isSelected === true) {
        row.isSelected = false
        row.select(false)
        this.closeEdit()
      } else {
        this.showDialog = true
        row.isSelected = true
        row.select(true)
      }
    },
    createNewPhone () {
      this.resetAlert()
      this.showDialog = true
      this.crud = 'Create'
      const base = cloneDeep(this.phoneData)
      this.phone = base
      this.editing = false
      this.creating = true
      if (this.phone_types.length > 0) {
        this.phone.phone_type = this.getPhoneTypeDefault()
      }
    },
    getPhoneTypeDefault () {
      const primary = this.phone_types.find(type => type && type.constant === 'PRIMARY')
      const mobile = this.phone_types.find(type => type && type.constant === 'MOBILE')
      if (this.phoneList.length > 0) {
        if (this.phoneList.some(phone => phone.phone_type.id === primary.id)) {
          return mobile
        }
      }
      return primary
    },
    closeEdit () {
      this.showDialog = false
      this.editing = false
      this.creating = false
      if (document.querySelector('.v-data-table__selected')) {
        document.querySelector('.v-data-table__selected').click()
      }
    },
    validate () {
      this.valid = this.$refs.form.validate()
    },
    reset () {
      this.getPhoneList()

      this.closeEdit()
      this.crud = 'Create'
    },
    /* eslint-enable */
    back () {
      this.$router.push({ name: 'Parties' })
    }
  }
}
</script>
